import GLightbox from 'glightbox';
import Vue from 'vue';
//import Swiper from 'swiper';
import scroll from './scroll';
import Axios from 'axios';

//import 'swiper/swiper-bundle.css';
import 'glightbox/dist/css/glightbox.css';

window.GLightbox = GLightbox;
//window.Swiper = Swiper;
window.scroll = scroll;
window.Axios = Axios;


const Components = {
	'VLineForm':'VLineForm.vue',
	'VForm':'VForm.vue',
	'VPopup':'VPopup.vue',
}

Object.keys(Components).forEach(k => Vue.component(k, require('../vue-components/'+Components[k]).default))

window.App = new Vue({
	el: '#app',
	data:{
		requestUrl:'/app/api/',
	},
	methods:{
		scrollTo(){
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		}
	},
	mounted(){
		this.$el.querySelectorAll('[data-modal]').forEach(button => {
			let modalName = button.getAttribute('data-modal');
			button.addEventListener('click', () => {
				let modalName = button.getAttribute('data-modal');
				if(modalName && window.popups[modalName]) window.popups[modalName].open();
			})
		})

	},
});

require('./y-map');
require('./script');