<template>
	<form class="form-container mb4" @submit.prevent="_onSubmit">
		<div class="df al-center vl-top w100 form-container-box">
			<div class="w40 al-left p01 form-container-text">
				<div class="h2">Нужна помощь?</div>
				<p>Специалист перезвонит, проконсультирует и ответит на все Ваши вопросы</p>
			</div>
			<div class=" w30 al-left form-container-input">
				<input type="text" class="input w100 mb0" required placeholder="Номер телефона" v-model="phone">
				<small style="display: block;margin-top: 4px">Отправляя запрос, Вы подтверждаете согласие на <a class="link" href="/uploads/Privacy.pdf" target="_blank">обработку персональных данных</a>.</small>
			</div>
			<div class=" w30 al-left p01 form-container-btn">
				<button class="btn --pink w100 -pink" :class="{'--is-loading':state.isLoadingSend}" :disabled="state.isLoadingSend"><span>Перезвоните мне</span></button>
			</div>
		</div>
	</form>
</template>
<script>

	export default {
		name: "v-line-form",
		data(){

			return {
				phone:'',
				state:{
					isLoaded:false,
                    isLoadingSend:false,
				}
			}
		},
		computed:{

		},
		mounted(){

		},
		methods:{
			getData(){
				let data = [];

				data.push({
					section:'Клиент',
					code:'phone',
					title:'Телефон',
					value:this.phone,
				})
				
				return data;
			},
			resetForm(){
				this.name = '';
				this.phone = '';
				setTimeout(() => this.state.isSuccess = false, 5000);
			},
			_onSubmit(){

				let params = new URLSearchParams();
				params.append('component','lead');
				params.append('action','lead');

                this.state.isLoadingSend = true;
                Axios.post('/app/api/'+'?'+params.toString(), this.getData()).then(r => r.data).then(result => {
                    this.state.isLoadingSend = false;
                    if(result && result.result){
                        this.resetForm();
                        window.popups.thanks.open()
                        window.popups.lead.close()
                    }
                    else{
                        alert('Произошла ошибка при отправке письма. Попробуйте позже.');
                    }
                }).catch(err => {
                    console.log(err)
                    alert(err)
                });


			},

			isMobile(){
				return window.innerWidth < 808;
			},
			removeSpace(s){
				return String(s).replace(/\s/g,'')
			},
			addSpace(n){
				n = this.removeSpace(n);
				return String(n).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
			},
		}
	}
</script>