document.addEventListener('DOMContentLoaded', (e) => {
	const lightbox = GLightbox();

	let buttons = document.querySelectorAll('[data-event]');
	if(buttons.length)
	{
		buttons.forEach(button => button.addEventListener('click', e => {
			let eventName = button.dataset.event;
			let event = window.events[eventName];
			if(window.events[eventName]) window.events[eventName].call(this);

		}));
	}

})

window.events = {
	'mobile-navigation': () => {
		document.body.classList.toggle('navigation-visible')
	},
	'mobile-phones': () => {
		document.body.classList.toggle('phones-visible')
	},
};