<template>
	<form class="v-form" :class="{'--is-success':state.isSuccess}" @submit.prevent="_onSubmit">
		<div class="h2 v-form__title">Онлайн заявка</div>
		<div class="v-form__text">Заполните форму, мы Вас проконсультируем в самые короткие сроки</div>
		<div class="v-form__small">Это ни к чему Вас не обязывает</div>
		<div class="v-form__box">
			<input type="text" placeholder="Ваше имя" class="v-form__box-input" v-model="name">

		</div>

		<div class="v-form__box">
			<input type="tel" placeholder="Номер телефона" :required="true" class="v-form__box-input" v-model="phone">
			<small class="v-form__box-small">Укажите пожалуйста корректно, иначе мы не сможем с Вами связаться</small>
		</div>

		<button class="btn -pink w100 v-form__btn" :class="{'--is-loading':state.isLoadingSend}" :disabled="state.isLoadingSend"><span>Отправить</span></button>
		<div class="v-form__assept">Нажимая на кнопку "Отправить" Вы подтверждаете согласие на <a class="link" href="/uploads/Privacy.pdf" target="_blank">обработку персональных данных</a>.</div>
	</form>
</template>

<script>
	export default {
		name: 'v-form',
		data(){
			return {
				params:{
					url:'',
					theme:'Форма на странице ЖК',
				},
				state:{
					isLoadingComponent:false,
					isLoadingSend:false,
					isSuccess:false,
				},
				name:'',
				phone:'',
			}
		},
		computed:{},
		mounted (){
			if(this.$attrs && this.$attrs.params)
			{
				let attrs = JSON.parse(this.$attrs.params);
				Object.keys(attrs).forEach(k => {
					this.params[k] = attrs[k];
				})
			}
		},
		methods:{
			getData(){

				let data = [];

				data.push({
					section:'Клиент',
					code:'name',
					title:'Имя',
					value:this.name,
				})

				data.push({
					section:'Клиент',
					code:'phone',
					title:'Телефон',
					value:this.phone,
				})

				data.push({
					section:'Статистика',
					code:'address',
					title:'Страница',
					value:location.origin + location.pathname,
				})

				return data;

			},
			resetForm(){
				this.name = '';
				this.phone = '';
				setTimeout(() => this.state.isSuccess = false, 5000);
			},
			_onSubmit(){

				let params = new URLSearchParams();
				params.append('component','lead');
				params.append('action','lead');
				this.state.isLoadingSend = true;
				Axios.post('/app/api/'+'?'+params.toString(), this.getData()).then(r => r.data).then(result => {
                    this.state.isLoadingSend = false;
                    if(result && result.result){
                        this.resetForm();
                        window.popups.thanks.open()
                        window.popups.lead.close()
                    }
                    else{
                        alert('Произошла ошибка при отправке письма. Попробуйте позже.');
                    }
				}).catch(err => {
					console.log(err)
					alert(err)
				});

			},

			isMobile(){
				return window.innerWidth < 808;
			},
			removeSpace(s){
				return String(s).replace(/\s/g,'')
			},
			addSpace(n){
				n = this.removeSpace(n);
				return String(n).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
			},
		}
	};
</script>

<style >
</style>
