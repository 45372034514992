var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "v-form",
      class: { "--is-success": _vm.state.isSuccess },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm._onSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "h2 v-form__title" }, [_vm._v("Онлайн заявка")]),
      _vm._v(" "),
      _c("div", { staticClass: "v-form__text" }, [
        _vm._v(
          "Заполните форму, мы Вас проконсультируем в самые короткие сроки"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "v-form__small" }, [
        _vm._v("Это ни к чему Вас не обязывает")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "v-form__box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          staticClass: "v-form__box-input",
          attrs: { type: "text", placeholder: "Ваше имя" },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "v-form__box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phone,
              expression: "phone"
            }
          ],
          staticClass: "v-form__box-input",
          attrs: { type: "tel", placeholder: "Номер телефона", required: true },
          domProps: { value: _vm.phone },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.phone = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("small", { staticClass: "v-form__box-small" }, [
          _vm._v(
            "Укажите пожалуйста корректно, иначе мы не сможем с Вами связаться"
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn -pink w100 v-form__btn",
          class: { "--is-loading": _vm.state.isLoadingSend },
          attrs: { disabled: _vm.state.isLoadingSend }
        },
        [_c("span", [_vm._v("Отправить")])]
      ),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "v-form__assept" }, [
      _vm._v('Нажимая на кнопку "Отправить" Вы подтверждаете согласие на '),
      _c(
        "a",
        {
          staticClass: "link",
          attrs: { href: "/uploads/Privacy.pdf", target: "_blank" }
        },
        [_vm._v("обработку персональных данных")]
      ),
      _vm._v(".")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }